import { http } from 'connex-cds';

export default {
  getInsightsWorkbooks: ({ entityRef }) =>
    http.get({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks` }),
  createInsightsWorkbook: ({ entityRef, data }) =>
    http.post({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks`, data }),
  updateInsightsWorkbook: ({ entityRef, crn, data }) =>
    http.patch({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks/${crn}`, data }),
  getInsightsWorkbookUrl: ({ entityRef, crn }) =>
    http.get({ apiName: 'assurance', path: `/${entityRef}/insights/insights-workbooks/${crn}/url` }),
};
