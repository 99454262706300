import React from 'react';
import { API } from '../../components/api';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { EntityContext } from './context';
import { usePostMessageListener } from './usePostMessageListener';

export const EntityContextProvider = ({ children }) => {
  const { sendMessage } = usePostMessageContext();
  const [entityRef, _setEntityRef] = React.useState();

  const setEntityRef = React.useCallback(
    ref => {
      _setEntityRef(ref);
      sendMessage({ type: 'entity-ref', entityRef: ref });
    },
    [sendMessage]
  );

  React.useEffect(() => {
    sendMessage({ type: 'req-entity-context' });
  }, [sendMessage]);

  const entityRefListener = React.useCallback(
    message => {
      if (message.type === 'entity-context') {
        setEntityRef(message.entityRef);
        API.setAppEntityToken(message?.token?.value);
      }
    },
    [setEntityRef]
  );

  usePostMessageListener(entityRefListener);

  return <EntityContext.Provider value={{ entityRef, setEntityRef }}>{children}</EntityContext.Provider>;
};
