import { Auth, User } from 'connex-cds';
import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import menuConfig from '../../menu-config';

const { IFrameAuthenticated } = Auth;

const AuthenticatedRoutes = ({setMenuConfig}) => {

  const { entityRef } = useParams();
  const UserPermissions = User.usePermissions();

  useEffect(() => {
    let newMenuConfig = [...menuConfig];
    if (UserPermissions.isPlatformAdmin || UserPermissions.isPlatformSupport) {
      newMenuConfig = [
        { id: 'assurance', labelStringId: 'assurance', path: '/assurance', icon: 'fa-thin fa-truck-bolt' },
        { id: 'groups', labelStringId: 'groups', path: '/groups', icon: 'fa-thin fa-car-bus' },
        { id: 'devices', labelStringId: 'devices', path: '/devices', icon: 'fa-thin fa-laptop-mobile' },
        { id: 'defaultConfig', labelStringId: 'defaultConfig', path: '/default-config', icon: 'fa-thin fa-sliders' },
        { id: 'insights', labelStringId: 'insights', path: '/insights', icon: 'fa-thin fa-chart-line' },
        {
          id: 'insights-settings',
          labelStringId: 'insights-settings',
          path: '/insights-settings',
          icon: 'fa-thin fa-gear',
        }
      ]
    }

    setMenuConfig(newMenuConfig);
  }, [UserPermissions?.isPlatformAdmin, UserPermissions?.isPlatformSupport, setMenuConfig]);

  return <IFrameAuthenticated entityRef={entityRef} menuConfig={[]} />;
};

export default AuthenticatedRoutes;
