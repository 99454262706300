export const getPermittedMenuConfig = ({ menuConfig, userContext }) => {
  const { user, role } = userContext;

  return menuConfig.filter(c => {
    if (user?.isPlatformAdmin || user?.isPlatformSupport) return true;
    const configPermissions = [].concat(c?.permissions).filter(x => !!x);
    if (configPermissions?.length) {
      if (role?.permissions?.include?.('*')) return true;
      return configPermissions?.[c.permissionStrategy || 'some']?.(p => role?.permissions?.includes(p));
    }
    return true;
  });
};
