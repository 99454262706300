import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'fullyMixedParameters',
  labelStringId: 'fullyMixedParameters',
  testId: 'fullyMixedParameters',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    isStatusChangeEnable: Yup.boolean(),
    coefFluid: Yup.string(),
    coefSlope: Yup.string(),
    coefStiff: Yup.string(),
    limitFluid: Yup.string(),
    limitStiff: Yup.string(),
    limitSlope: Yup.string(),
    minimumDistanceValid: Yup.string(),
    minimumSlopeValid: Yup.string(),
    minTurnsReaction: Yup.string(),
    minTargetSlumpReaction: Yup.string(),
    minimumTurnsStiffAfterLoaded: Yup.string(),
    minimumTurnsFluidAfterLoaded: Yup.string(),
    minimumTurnsStiffAfterLoadedWB: Yup.string(),
    minimumTurnsFluidAfterLoadedWB: Yup.string(),
    minimumTurnsStiffAfterPMax: Yup.string(),
    minimumTurnsFluidAfterPMAX: Yup.string(),
    maximumTurnsAfterLoaded: Yup.string(),
    maximumTurnsAfterLoadedWB: Yup.string(),
  }),

  fields: [
    {
      path: 'isStatusChangeEnable',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'isStatusChangeEnable',
      testId: 'isStatusChangeEnable',
      multiline: true,
    },
    {
      path: 'coefFluid',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'coefFluid',
      testId: 'coefFluid',
      multiline: true,
    },
    {
      path: 'coefSlope',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'coefSlope',
      testId: 'coefSlope',
      multiline: true,
    },
    {
      path: 'coefStiff',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'coefStiff',
      testId: 'coefStiff',
      multiline: true,
    },
    {
      path: 'limitFluid',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'limitFluid',
      testId: 'limitFluid',
      multiline: true,
    },
    {
      path: 'limitStiff',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'limitStiff',
      testId: 'limitStiff',
      multiline: true,
    },
    {
      path: 'limitSlope',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'limitSlope',
      testId: 'limitSlope',
      multiline: true,
    },
    {
      path: 'minimumDistanceValid',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minimumDistanceValid',
      testId: 'minimumDistanceValid',
      multiline: true,
    },
    {
      path: 'minimumSlopeValid',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minimumSlopeValid',
      testId: 'minimumSlopeValid',
      multiline: true,
    },
    {
      path: 'minTurnsReaction',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minTurnsReaction',
      testId: 'minTurnsReaction',
      multiline: true,
    },
    {
      path: 'minTargetSlumpReaction',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minTargetSlumpReaction',
      testId: 'minTargetSlumpReaction',
      multiline: true,
    },
    {
      path: 'minimumTurnsStiffAfterLoaded',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minimumTurnsStiffAfterLoaded',
      testId: 'minimumTurnsStiffAfterLoaded',
      multiline: true,
    },
    {
      path: 'minimumTurnsFluidAfterLoaded',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minimumTurnsFluidAfterLoaded',
      testId: 'minimumTurnsFluidAfterLoaded',
      multiline: true,
    },
    {
      path: 'minimumTurnsStiffAfterLoadedWB',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minimumTurnsStiffAfterLoadedWB',
      testId: 'minimumTurnsStiffAfterLoadedWB',
      multiline: true,
    },
    {
      path: 'minimumTurnsFluidAfterLoadedWB',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minimumTurnsFluidAfterLoadedWB',
      testId: 'minimumTurnsFluidAfterLoadedWB',
      multiline: true,
    },
    {
      path: 'minimumTurnsStiffAfterPMax',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minimumTurnsStiffAfterPMax',
      testId: 'minimumTurnsStiffAfterPMax',
      multiline: true,
    },
    {
      path: 'minimumTurnsFluidAfterPMAX',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'minimumTurnsFluidAfterPMAX',
      testId: 'minimumTurnsFluidAfterPMAX',
      multiline: true,
    },
    {
      path: 'maximumTurnsAfterLoaded',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'maximumTurnsAfterLoaded',
      testId: 'maximumTurnsAfterLoaded',
      multiline: true,
    },
    {
      path: 'maximumTurnsAfterLoadedW',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'maximumTurnsAfterLoadedW',
      testId: 'maximumTurnsAfterLoadedW',
      multiline: true,
    },
  ],
};
