import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import style from './style.js';

const Styled = styled.div`
  ${style}
`;

export const Profile = ({ onClick }) => {
  return (
    <Styled className={cn('profile')}>
      <div className="icon" onClick={onClick}>
        <i className="fa-solid fa-user" />
      </div>
    </Styled>
  );
};
