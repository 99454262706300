import { Form } from 'connex-cds';
import * as Yup from 'yup';

export default {
  path: 'insightsWorkbooks',
  labelStringId: 'insightsWorkbooks',
  testId: 'insightsWorkbooks',
  keyPath: 'crn',
  validationSchema: Yup.object().shape({
    id: Yup.string().required('required'),
    name: Yup.string().required('required'),
    groupName: Yup.string().required('required'),
    reportName: Yup.string().required('required'),
    collectionName: Yup.string().required('required'),
    status: Yup.boolean().required('required'),
  }),
  fields: [
    {
      path: 'id',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'id',
      testId: 'id',
    },
    {
      path: 'name',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'name',
      testId: 'name',
    },
    {
      path: 'groupName',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'groupName',
      testId: 'groupName',
    },
    {
      path: 'reportName',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'reportName',
      testId: 'reportName',
    },
    {
      path: 'collectionName',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'collectionName',
      testId: 'collectionName',
    },
    {
      path: 'toolbar',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'showToolbar',
      testId: 'showToolbar',
    },
    {
      path: 'tooltip',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'showTooltip',
      testId: 'showTooltip',
    },
    {
      path: 'tabs',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.boolean,
      labelStringId: 'showTabs',
      testId: 'showTabs',
    },
    {
      path: 'icon',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      labelStringId: 'icon',
      testId: 'icon',
    },
    {
      path: 'status',
      // valuePath: 'status',
      displayPath: 'label',
      dataType: Form.FIELD_TYPES.string,
      defaultValue: 'ACTIVE',
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      labelStringId: 'status',
      testId: 'status',
    },
  ],
};
