import { isEmpty } from 'lodash';
import { Tabs as AntTabs } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { useTranslateObjects } from '../../localization';
import style from './style';

const StyledTabs = styled(AntTabs)`
  ${style}
`;

const { TabPane } = AntTabs;

export const Tabs = ({ config, tabPosition = 'top', ...props }) => {
  const { translateObjects } = useTranslateObjects();

  const tabsConfig = React.useMemo(
    () => (config?.translate ? translateObjects(config.tabs, config.translate) : config.tabs),
    [config.tabs, config.translate, translateObjects]
  );

  return (
    <StyledTabs defaultActiveKey={0} tabPosition={tabPosition}>
      {tabsConfig.map(tab => {
        // tab.component may be a single component or an array of components.  Either way, ensure we have an array.
        const components = [].concat(tab.component);

        // If we have props, pass to components.
        return (
          <TabPane tab={<span data-testid={tab.testId}>{tab.name}</span>} key={tab.testId} disabled={tab.disabled}>
            {isEmpty(props) ? tab.component : components.map(c => React.cloneElement(c, props))}
          </TabPane>
        );
      })}
    </StyledTabs>
  );
};
