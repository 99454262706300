import { Core, Form, Modal } from 'connex-cds';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  useListInsightsWorkbooks,
  useCreateInsightsWorkbook,
  useUpdateInsightsWorkbook,
} from '../../query-hooks/insights';
import insightsWorkbooksConfig from './utils/insightsWorkbooksConfig';

export const InsightsSettings = () => {
  const listInsightsWorkbooks = useListInsightsWorkbooks();
  const { mutate: insightsWorkbookCreate } = useCreateInsightsWorkbook();
  const { mutate: insightsWorkbookUpdate } = useUpdateInsightsWorkbook();

  const { DETAIL_TYPES, ListAndDetail } = Form;
  const { openModal, closeModal } = Modal.useModalContext();
  const { entityRef } = useParams();
  const [refetchList, setRefetchList] = useState(false);

  const saveInsightsWorkbook = useCallback(
    item => {
      insightsWorkbookCreate({
        entityRef,
        data: {
          ...item,
          crn: undefined,
        },
      });
    },
    [insightsWorkbookCreate, entityRef]
  );

  const updateInsightsWorkbook = useCallback(
    item => {
      insightsWorkbookUpdate({
        entityRef,
        crn: item.crn,
        data: {
          ...item,
          crn: undefined,
        },
      });
    },
    [insightsWorkbookUpdate, entityRef]
  );

  useEffect(() => {
    if (refetchList) {
      listInsightsWorkbooks.refetch(true);
      setRefetchList(false);
    }
  }, [listInsightsWorkbooks, refetchList]);

  return (
    <Core.Spinner spin={listInsightsWorkbooks.isLoading || listInsightsWorkbooks.isRefetching}>
      <ListAndDetail
        detailOptions={{ width: '900px' }}
        detailType={DETAIL_TYPES.MODAL}
        showHeader={false}
        config={insightsWorkbooksConfig}
        listQuery={listInsightsWorkbooks}
        createQuery={saveInsightsWorkbook}
        updateQuery={updateInsightsWorkbook}
      />
    </Core.Spinner>
  );
};
