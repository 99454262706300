import React from 'react';
import { isObject } from 'lodash';

import deprecated from '../../util/validation/deprecated';
import TranslateString from '../localization/util/translate-string-id/TranslateString';

export const Translate = ({ stringId, values, testId: testIdDeprecated, 'data-testid': dataTestId, htmlId }) => {
  deprecated({ testId: testIdDeprecated }, { testId: 'Use data-testid' });
  const dataAttributes = {
    'data-stringid': isObject(stringId) ? stringId.key : stringId,
    'data-testid': dataTestId || testIdDeprecated,
  };

  const options = React.useMemo(() => {
    if (isObject(stringId)) {
      return {
        stringId: stringId.key,
        values: stringId.values,
      };
    }

    return {
      stringId,
      values,
    };
  }, [stringId, values]);

  if (!options.stringId) {
    console.error('Missing stringId');
    return null;
  }

  return (
    <span {...dataAttributes} id={htmlId}>
      <TranslateString stringId={options.stringId} values={options.values} />
    </span>
  );
};
