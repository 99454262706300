import { Outlet } from 'react-router-dom';
import { AppHeader } from '../AppHeader';
import { Sidebar } from './sidebar/Sidebar';

export const HeaderAndSidebar = () => {
  return (
    <Sidebar>
      <AppHeader />
      <Outlet />
    </Sidebar>
  );
};
