import { useParams } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Insights } from '../api';

export const useListInsightsWorkbooks = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['useListInsightsWorkbooks', entityRef],
    queryFn: () => Insights.getInsightsWorkbooks({ entityRef }),
    enabled: true,
    retry: 0,
  });

  if (!entityRef) {
    console.trace();
    return undefined;
  }

  return query;
};

/**
 * Function for Post the device group
 * @author Juan David Reina
 */
export const useCreateInsightsWorkbook = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['useListInsightsWorkbooks', entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      return Insights.createInsightsWorkbook({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['useListInsightsWorkbooks', entityRef] });
    },
  });

  return mutation;
};

/**
 * Function for Patch/Update a devices group
 * @author Juan David Reina
 */
export const useUpdateInsightsWorkbook = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['useListInsightsWorkbooks', entityRef];

  const mutation = useMutation({
    mutationFn: data => {
      Insights.updateInsightsWorkbook({ ...data });
    },
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['useListInsightsWorkbooks', entityRef] });
    },
  });

  return mutation;
};

export const useGetInsightsWorkbookUrl = insightsWorkbookRef => {
  const { entityRef } = useParams();
  const query = useQuery({
    queryKey: ['useGetInsightsWorkbookUrl'],
    queryFn: crn => Insights.getInsightsWorkbookUrl({ entityRef, crn: insightsWorkbookRef }),
    refetchOnMount: 'always',
    enabled: true,
    retry: 0,
  });

  return query;
};
