import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { DrawerProvider } from '../../components/drawer';
import { Translate } from '../../components/localization';
import { AppSwitcher } from './app-switcher/AppSwitcher';
import { EntitySwitcher } from './entity-switcher/EntitySwitcher';
import { Profile } from './profile/Profile';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const Header = ({ className, appContext, entityContext, userContext, onChange, onProfileClick }) => {
  const { isEntityPickerRequired, appEntities, currentFeature } = appContext;
  const { entityRef, setEntityRef } = entityContext;
  const { userApps, userAppsIsLoading } = userContext;

  return (
    <Styled>
      <DrawerProvider>
        <div className={cn('header', className)}>
          <div className={cn('left', { visible: !!currentFeature?.id })}>
            <div className={cn('feature-name')}>
              <Translate stringId={currentFeature?.labelStringId} />
            </div>
            {isEntityPickerRequired ? (
              <EntitySwitcher value={entityRef} onChange={setEntityRef} entities={appEntities} />
            ) : null}
          </div>
          <div className="right">
            <div className="center-aligned">
              <AppSwitcher
                onChange={onChange}
                userApps={userApps}
                isLoading={userAppsIsLoading}
                appContext={appContext}
              />
              <Profile onClick={onProfileClick} />
            </div>
          </div>
        </div>
      </DrawerProvider>
    </Styled>
  );
};
